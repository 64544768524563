import Image from 'next/future/image';
import { FormattedMessage } from 'react-intl';

import { PHONE_NUMBERS, SUCCESS_IMG } from '@/utils/constants';

const ThankyouContent = ({ phoneNumber = PHONE_NUMBERS.SELF_STORAGE }) => {
  return (
    <>
      <Image
        sizes="(max-width: 375px) 180px, (max-width: 576px) 180px, (max-width: 768px) 180px, (max-width: 992px) 200px, (max-width: 1280px) 200px"
        src={SUCCESS_IMG}
        className="img-fluid center-block mx-auto mt-4"
        alt="success-img"
        width="200"
        height="300"
      />
      <div className="main-heading mt-3 text-center">
        <FormattedMessage id="thank-you-success-modal"></FormattedMessage>
      </div>
      <p className="para m-2 mx-md-5 mb-md-4 text-center">
        <FormattedMessage
          id="form.thankyouMessage"
          values={{
            phone_number: <b>{phoneNumber}</b>,
          }}
        />
      </p>
    </>
  );
};
export default ThankyouContent;
