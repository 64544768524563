import { doDataVaultRequest, makeRequest } from '@/utils/request';

import * as urls from '../apiUrl';
import {
  CHAT_BOT_CALLBACK_CONTACT_UPDATE_API,
  CHAT_BOT_CALLBACK_EXISTING_CLIENT_API,
  REFERRAL_POST_API,
} from '../apiUrl';

const callbackContactUsApis = async (data: any) => {
  return doDataVaultRequest(`${urls.CONTACT_POST_API}`, 'post', data);
};

const callbackValetServiceApis = async (data: any) => {
  return doDataVaultRequest(`${urls.BOOK_POST_API}`, 'post', data);
};

const callbackCallMeApis = async (
  data: any,
  formType: any,
  storeType: any = 'ss'
) => {
  return doDataVaultRequest(
    `${urls.getcallMebackUrl(formType, storeType)}`,
    'post',
    data
  );
};

const callbackSmallBusinessApis = async (data: any) => {
  return doDataVaultRequest(`${urls.SMALL_BUSINESS_POST_API}`, 'post', data);
};

const callbackTeamCanadaApis = async (data: any) => {
  return doDataVaultRequest(`${urls.TEAM_CANADA_POST_API}`, 'post', data);
};

const callbackChatApis = async (existingClient: boolean, data: any) => {
  const url = existingClient
    ? CHAT_BOT_CALLBACK_EXISTING_CLIENT_API
    : CHAT_BOT_CALLBACK_CONTACT_UPDATE_API;
  const headers = {
    Accept: 'application/json',
    'Content-type': 'application/json',
    'x-api-key': `${process.env.DATAVAULT_API_GATEWAY_CHAT_BOT_TOKEN}`,
  };
  return makeRequest(url, 'post', data, {}, headers);
};

const referralCallApis = async (data: any) => {
  const headers = {
    Accept: 'application/json',
    'Content-type': 'application/json',
    'x-api-key': `${process.env.DATA_VAULT_TOKEN}`,
  };
  return makeRequest(REFERRAL_POST_API, 'post', data, {}, headers);
};

export {
  callbackCallMeApis,
  callbackChatApis,
  callbackContactUsApis,
  callbackSmallBusinessApis,
  callbackTeamCanadaApis,
  callbackValetServiceApis,
  referralCallApis,
};
